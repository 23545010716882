import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


export const ServerBooter = () => {
    const [open, setOpen] = React.useState(true);
    const [downloading, setDownloading] = React.useState(true);
    const [text, setText] = React.useState("Downloading boot script...");

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        async function bootServer() {
            // Try to open protocol.
            await delay(1000);
            window.open("https://abxshield.com/startServer.bat");
            setDownloading(false);
            setText("Your server is not active. Please run the boot script that was just downloaded, wait for server startup confirmation on the console, and then attempt to login again.");
            await delay(6000);
            setText("Have a good day 😊");
            await delay(1000);
            handleClose();
        }

        bootServer();
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} >
            <DialogContent>
                <DialogContent>
                    {downloading ? <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> : null}
                    <DialogContentText>
                        {text}
                    </DialogContentText>
                </DialogContent>
            </DialogContent>
        </Dialog>
    );
}


const delay = ms => new Promise(res => setTimeout(res, ms));

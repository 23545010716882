import PropTypes from 'prop-types';
import { useRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { Slide } from '@mui/material';


// ----------------------------------------------------------------------

NotistackProvider.propTypes = {
    children: PropTypes.node,
};

export default function NotistackProvider({ children }) {
    const notistackRef = useRef(null);

    return (
        <>
            <SnackbarProvider
                ref={notistackRef}
                dense
                maxSnack={5}
                preventDuplicate
                autoHideDuration={4000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                TransitionComponent={Slide}
            >
                {children}
            </SnackbarProvider>
        </>
    )
}